import moment from 'moment'
import "owl.carousel"

jQuery(document).ready(function ($) {

	// TOUR IMPORT
	$.ajax({
		url: 'https://rest.bandsintown.com/artists/id_15487659/events?app_id=45PRESS_GEORGIA_WEBSTER',
		method: 'GET',
		dataType: 'json',

		error: () => {
			alert('Error fetching events!');
		},

		success: data => {
			const events = $('#events');
			let html = '';
			if (data.length) {
				for (let event of data) {
					let location = event.venue.city + ', ' + event.venue.region;

					if (location === ', ') {
						location = '';
					}

					html += '<div class="event-wrapper less">';
					html += '<div class="event-date">' + moment(event.datetime).format('MMM D') + '</div>';
					html += '<div class="event-location">' + location + '</div>';
					html += '<div class="event-venue">' + event.venue.name + '</div>';
					html += '<div class="event-links">';

					for (let offer of event.offers) {
						html += '<a href="' + offer.url + '" target="_blank" class="btn">' + offer.type + '</a>';
					}

					html += '</div>';
					html += '</div>';
				}

				events.html(html);

			} else {
				events.html('<p class="no-events">No upcoming events.</p>');
			}
		}
	});



	// MUSIC CAROUSEL
	function createMusicCarousel() {
		const musicCarousel = $("#music-carousel")
		const leftArrow = '<div class="owl-custom-nav-prev arrow"><img src="./dist/img/icon/arrow-left.svg" alt="Right Arrow"></div>'
		const rightArrow = '<div class="owl-custom-nav-next arrow"><img src="./dist/img/icon/arrow-right.svg" alt="Right Arrow"></div>'

		musicCarousel.owlCarousel({
			loop: true,
			nav: true,
			dots: false,
			items: 1,
			startPosition: 0,
			center: true,
			navText: [
				leftArrow,
				rightArrow
			]
		})
	}
	createMusicCarousel()

	// VIDEO CAROUSEL
	function createVideoCarousel() {
		const videoCarousel = $("#video-carousel")
		const leftArrow = '<div class="owl-custom-nav-prev arrow"><img src="./dist/img/icon/arrow-left.svg" alt="Right Arrow"></div>'
		const rightArrow = '<div class="owl-custom-nav-next arrow"><img src="./dist/img/icon/arrow-right.svg" alt="Right Arrow"></div>'

		videoCarousel.owlCarousel({
			loop: true,
			nav: true,
			dots: false,
			items: 1,
			startPosition: 0,
			center: true,
			navText: [
				leftArrow,
				rightArrow
			]
		})
	}
	createVideoCarousel()

	$('#toggle-tour-dates').click(function () {
		$('#events .event-wrapper').toggleClass('less');

		if ($('#toggle-tour-dates').hasClass('less')) {
			$('#toggle-tour-dates').html('View All Tour Dates <i class="fa-thin fa-arrow-right"></i>')
			$('#toggle-tour-dates').removeClass('less')
		} else {
			$('#toggle-tour-dates').html('Show Less Tour Dates <i class="fa-thin fa-arrow-right"></i>')
			$('#toggle-tour-dates').addClass('less')
		}
	})

	// MOBILE MENU
	$('.mobile-menu-icon').on('click', function () {
		$("#navbar").toggleClass('active')
		$(".line").toggleClass('active')
	})

	$('.nav-button').on('click', function () {
		$("#navbar").removeClass('active')
		$(".line").removeClass('active')
	})

	// Scroll
	$('.scroll').on('click', function (e) {
		e.preventDefault();

		const href = $(this).attr('href');
		const targetOffset = $(href).offset().top;
		const animationDuration = 500;

		$('html, body').animate({
			scrollTop: targetOffset
		},
			animationDuration
		)
	});
})